import commonStyles from '../styles/components/common.module.css';
import styles from '../styles/pages/common.module.css';
import inputStyles from '../styles/components/Input.module.css';

import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Spinner from '../components/Spinner';

import { GET_MEETING_QUESTIONS_SUBSCRIPTION, GET_MEETING_QUESTION_RESPONSES_SUBSCRIPTION, SET_RESPONSE } from '../queries/question'

import { useSubscription, useMutation } from '@apollo/client'
import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast'

const Questions = () => {
  const meeting = "1c2ff68f-1e57-47d5-9776-3e1ac99e72d3";

  const now = new Date();
  const then = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,0,0);
  const [currentSeconds, setCurrentSeconds] = useState((new Date().getTime() - then.getTime())/1000);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSeconds((new Date().getTime() - then.getTime())/1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  function convertTimetoSeconds(inputTime){
    const [myTime, timezone] = inputTime.split('+');
    const [hours, minutes, seconds] = myTime.split(':');
    return (Number(hours)) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  };

  const { user } = useOutletContext();
  const { loading: isLoadingMeetingQuestions
    , error: isErrorMeetingQuestions
    , data: meetingQuestionsData } = useSubscription(GET_MEETING_QUESTIONS_SUBSCRIPTION, {
    variables: { meeting },
    skip: !meeting
  })
  const meetingQuestions = isLoadingMeetingQuestions? [] 
    : [...meetingQuestionsData?.questions]
      .sort((a,b)=>(convertTimetoSeconds(b.starttime) - convertTimetoSeconds(a.starttime)));

  const { loading: isLoadingMeetingQuestionResponses
    , error: isErrorMeetingQuestionResponses
    , data: meetingQuestionResponsesData } = useSubscription(GET_MEETING_QUESTION_RESPONSES_SUBSCRIPTION, {
    variables: { meeting },
    skip: !meeting
  })
  const meetingQuestionResponses = isLoadingMeetingQuestionResponses? [] 
    : [...meetingQuestionResponsesData?.responses];

  const error = '';
  const isError = false;

  const [setResponse, { loading: updatingResponse }] = useMutation(SET_RESPONSE);

  const handleOnSubmit = async e => {
    e.preventDefault();
    try {
      await setResponse({
        variables: {
          user: user.id,
          question: e.target.id,
          privatemeta: {
            "response": e.target.name
          }
        }
      })
      toast.success('Response recorded', { id: 'setResponse' })
    } catch (error) {
      toast.error('Unable to record response\n\n' + error.message, { id: 'setResponse' })
    }
  };

    const handleCheck = async e => {
      if(e.target.checked) {
        setVotes([...votes,e.target.getAttribute('value')]);
      } else {
        setVotes(votes.filter(item => item !== e.target.getAttribute('value')));
      }
    };

    const handleOnSubmitBallot = async e => {
    e.preventDefault();
    try {
      await setResponse({
        variables: {
          user: user.id,
          question: e.target.id,
          privatemeta: {
            "selected": votes
            ,"writeInList": writeInList
            ,"noneOfTheAbove": noneOfTheAbove
          }
        }
      })
      toast.success('Response recorded', { id: 'setResponse' })
      setHasVoted(true);
    } catch (error) {
      toast.error('Unable to record response\n\n' + error.message, { id: 'setResponse' })
    }
  };

  const [noneOfTheAbove, setNoneOfTheAbove] = useState(false);
  const [votes, setVotes] = useState([]);
  const [hasVoted, setHasVoted] = useState(false);

  let writeIns = [];

  const [writeIn, setWriteIn] = useState('');
  const [writeInList, setWriteInList] = useState([]);
  const handleWriteInClick = async e => {
    e.preventDefault();
    if (writeIn.trim()) {
      setWriteInList([...writeInList, writeIn]);
      setWriteIn('');
    }
  };

  useEffect(() => {
    if (noneOfTheAbove) {
      setWriteInList([]);
      setVotes([]);
    }
  }, [noneOfTheAbove]);

  return (
    <>
      <Helmet>
        <title>Questions - WebMeetings</title>
      </Helmet>

      <div>
        <h2 className={styles.title}>Questions</h2>
      </div>

      { updatingResponse ? null : 
        [...meetingQuestions]        
        .map(question => (
          question.publicmeta?.type == "ynaPoll" ? (
            <div className={commonStyles.card} key={question.id}>
                <h3 className={commonStyles['sub-title']}>{question.label}</h3>
                {
                  Number(convertTimetoSeconds(question.starttime)) + Number(question.durationseconds) < Number(currentSeconds) ?
                    <p className={commonStyles.text}>Expired</p>
                    : <>
                        <p className={commonStyles.text}>{Math.round(convertTimetoSeconds(question.starttime) + question.durationseconds - currentSeconds,0)} seconds left</p>
                        <form id={question.id} name="Yes" onSubmit={handleOnSubmit} className={commonStyles.form}>
                          <button
                            type="submit"
                            disabled={false}
                            className={commonStyles.button}
                            style={{backgroundColor: 'green'}}
                          >
                            {false ? <Spinner size="sm" /> : 'Yes'}
                          </button>
                        </form>
                        <form id={question.id} name="Abstain" onSubmit={handleOnSubmit} className={commonStyles.form}>
                          <button
                            type="submit"
                            disabled={false}
                            className={commonStyles.button}
                            style={{backgroundColor: 'grey'}}
                          >
                            {false ? <Spinner size="sm" /> : 'Abstain'}
                          </button>
                        </form>
                        <form id={question.id} name="No" onSubmit={handleOnSubmit} className={commonStyles.form}>
                          <button
                            type="submit"
                            disabled={false}
                            className={commonStyles.button}
                            style={{backgroundColor: 'red'}}
                          >
                            {false ? <Spinner size="sm" /> : 'No'}
                          </button>
                        </form>
                      </>
                }
                <ul className={commonStyles.resultList}>{/*
                  question.publicmeta?.results?.map((result,index) => (
                    <li className={commonStyles.resultListItem} key={index}><span className={commonStyles[result.label]}>{result.label}&nbsp;~&nbsp;{result.count}&nbsp;~&nbsp;({result.percentage}%)</span></li>
                  ))
                */}</ul>
                <ul className={commonStyles.resultList}>
                <li className={commonStyles.resultListItem} key={1}><span className={commonStyles["Yes"]}>Yes&nbsp;~&nbsp;{meetingQuestionResponses.filter((response) => (response.question === question.id && response.privatemeta.response === "Yes")).length}&nbsp;~&nbsp;({Math.round(meetingQuestionResponses.filter((response) => (response.question === question.id && response.privatemeta.response === "Yes")).length / meetingQuestionResponses.filter((response) => (response.question === question.id && response.privatemeta.response !== "Abstain")).length * 100,0) || 0}%)</span></li>
                <li className={commonStyles.resultListItem} key={2}><span className={commonStyles["No"]}>No&nbsp;~&nbsp;{meetingQuestionResponses.filter((response) => (response.question === question.id && response.privatemeta.response === "No")).length}&nbsp;~&nbsp;({Math.round(meetingQuestionResponses.filter((response) => (response.question === question.id && response.privatemeta.response === "No")).length / meetingQuestionResponses.filter((response) => (response.question === question.id && response.privatemeta.response !== "Abstain")).length * 100,0) || 0}%)</span></li>
                <li className={commonStyles.resultListItem} key={3}><span className={commonStyles["Abstain"]}>Abstain&nbsp;~&nbsp;{meetingQuestionResponses.filter((response) => (response.question === question.id && response.privatemeta.response === "Abstain")).length}</span></li>
                </ul>
                <p className={commonStyles.text}>{meetingQuestionResponses.filter((response) => (response.question === question.id)).length} responses submitted.</p>
                <ul className={commonStyles.responseList}>{/*
                  question.publicmeta?.responses?.map((response,index) => (
                    <li className={commonStyles.responseListItem} key={index}><span className={commonStyles[response.response]}>{response.displayName}&nbsp;~&nbsp;{response.response.charAt(0)}</span></li>
                  ))
                  */
                  meetingQuestionResponses.filter((response) => (response.question === question.id)).map((response,index) => (
                    <li className={commonStyles.responseListItem} key={index}><span className={commonStyles[response.privatemeta.response]}>{response.userByUser.displayName}&nbsp;~&nbsp;{response.privatemeta.response.charAt(0)}</span></li>
                  ))
                }</ul>
                {isError ? (
                  <p>{error?.message}</p>
                ) : null}
            </div>
          ) : (
            //nwBallot
            <div className={commonStyles.card} key={question.id}>
                <h3 className={commonStyles['sub-title']}>{question.label}</h3>
                {
                  Number(convertTimetoSeconds(question.starttime)) + Number(question.durationseconds) < Number(currentSeconds) ?
                    <p className={commonStyles.text}>Expired</p>
                    : <>
                      <p className={commonStyles.text}>{Math.round(convertTimetoSeconds(question.starttime) + question.durationseconds - currentSeconds,0)} seconds left</p>
                      <p className={commonStyles.text}>Make up to {question.publicmeta?.maxSelections} choices</p>
                      {/* <p className={commonStyles.text}>12 of {question.publicmeta?.maxSelections} choices left</p> */}
                      <form id={question.id} name="SendVote" onSubmit={handleOnSubmitBallot} className={commonStyles.form}>
                        {question.publicmeta?.choices.map((choice)=>(
                          <div className={inputStyles.inlineContainer}>
                            <input className={inputStyles.checkbox} type="checkbox" id={choice.slug} name={choice.slug} value={choice.slug} disabled={!(votes.indexOf(choice.slug) > -1) && (votes.length >= question.publicmeta?.maxSelections)} checked={votes.indexOf(choice.slug) > -1} onClick={handleCheck} />
                            <label className={inputStyles.label} for={choice.slug}>{choice.title}</label>
                          </div>
                        ))}
                        {/* {[...writeInList,""].map((writein,index)=>(
                          <div className={inputStyles.container}>
                            <label className={inputStyles.label} for={"writein"+(index + 1).toString().padStart(2,"0")}>Write-In {(index + 1).toString().padStart(2,"0")}</label>
                            <div className={inputStyles.inlineContainer}>
                              <input className={inputStyles.input} type="text" id={"writein"+(index + 1).toString().padStart(2,"0")} name="chosen" value={writeIn} onChange={(_e, { value }) => setWriteIn(value)} />
                              <button className={commonStyles.button} style={{width: '150px', color: 'black', backgroundColor: 'yellow'}} onClick={handleWriteInClick}>Add Write In</button>
                            </div>
                          </div>
                        ))} */}
                        {/* {[...writeIns,""].map((writein,index)=>(
                          <div className={inputStyles.container}>
                            <label className={inputStyles.label} for={"writein"+(index + 1).toString().padStart(2,"0")}>Write-In {(index + 1).toString().padStart(2,"0")}</label>
                            <input className={inputStyles.input} type="text" id={"writein"+(index + 1).toString().padStart(2,"0")} name="chosen" />
                          </div>
                        ))} */}
                        <div className={inputStyles.inlineContainer}>
                          <input className={inputStyles.checkbox} type="checkbox" checked={noneOfTheAbove} onClick={() => setNoneOfTheAbove(!noneOfTheAbove)} id="nota" name="nota" />
                          <label className={inputStyles.label} for="nota">NOTA: None Of The Above</label>
                        </div>
                        {hasVoted ? <p>Your vote has been received.</p> :
                        <button
                        type="submit"
                        disabled={false}
                        className={commonStyles.button}
                        style={{backgroundColor: 'green'}}
                      >
                        {false ? <Spinner size="sm" /> : 'Send Ballot'}
                      </button>
                      }
                      </form>
                    </>
                }
                <p className={commonStyles.text}>{meetingQuestionResponses.filter((response) => (response.question === question.id)).length} ballots submitted.</p>
                <ul className={commonStyles.responseList}>{
                  meetingQuestionResponses.filter((response) => (response.question === question.id)).map((response,index) => (
                    <li className={commonStyles.responseListItem} key={index}><span className={commonStyles["Abstain"]}>{response.userByUser.displayName}</span></li>
                  ))
                }</ul>
            </div>
          )
        ))
      }      
    </>
  );
};

export default Questions;
